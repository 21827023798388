<template>
  <div>
    <span class="link link--bold link--underline" @click="conectarStripe = true">
      {{ $t("piggybank.iban.enter") }}
    </span>

    <modal-dialog :show="conectarStripe" @close="conectarStripe = false" target-class="modal__card--mini">
      <div>
        <header class="mch__form-header">
          <div class="mch__form-title" v-if="normalFlow">{{ $t("piggybank.iban.title1") }}</div>
          <div class="mch__form-title" v-else>{{ $t("piggybank.iban.title2") }}</div>
        </header>

        <InputText
          v-model="AccountHolderName"
          :label="$t('piggybank.iban.holderName')"
          :placeholder="$t('piggybank.iban.holderNamePlaceholder')"
          required
        />

        <div class="bb-form-fieldset">
          <InputText
            v-model="AccountNumber"
            :label="$t('piggybank.iban.cc')"
            :placeholder="$t('piggybank.iban.ccPlaceholder')"
            required
            :errorLabel="errorIBAN"
            @input="validateIBANOnInput(AccountNumber)"
          />
        </div>
        <CrearDisclaimerStripe />
        <footer class="mch__actions">
          <bbButton
            :label="$t('generic.save')"
            :disabled="!formIsValid"
            :loading="loading"
            @click="enviarDatosIBAN"
            class="button button--primary button--block button--icoEnd"
          />
        </footer>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ModalDialog from "../../../components/ui/ModalDialog.vue";
import InputText from "../../../components/forms/InputText.vue";
import CrearDisclaimerStripe from "./CrearDisclaimer.vue";
import { mapGetters } from "vuex";
import bbButton from "../../../components/buttons/bbButton.vue";

export default {
  name: "HuchaAgregarIban",
  components: {
    bbButton,
    CrearDisclaimerStripe, InputText, "modal-dialog": ModalDialog
  },
  computed: {
    ...mapGetters({
      user: "user",
      countryCode: "countryCode",
    })
  },
  data() {
    return {
      conectarStripe: false,
      AccountNumber: "",
      AccountHolderName: "",
      errorIBAN: "",
      formIsValid: false,
      loading: false,
      normalFlow: true
    };
  },
  methods: {
    validateForm() {
      this.formIsValid = this.AccountNumber.trim() !== "" && this.AccountHolderName.trim() !== "" && this.errorIBAN === "";
    },
    async enviarDatosIBAN() {
      this.loading = true;
      if (this.formIsValid) {
        const country = (this.countryCode === "FR" || this.countryCode === "IT")
          ? this.countryCode
          : "ES";

        try {
          const payload = {
            AccountNumber: this.AccountNumber,
            Country: country,
            Currency: "eur",
            AccountHolderName: this.AccountHolderName,
            userAccountId: this.user.stripeAccountId
          };
          const response = await this.$store.dispatch("addIban", { payload });

          if (response.status === 200) {
            this.conectarStripe = false;
            this.$emit("ibanAdded");
          } else {
            this.conectarStripe = false;
            this.$emit("errorAddingIban", response.data.message || this.$t("piggybank.iban.error"));
            this.loading = false;
          }
        } catch (error) {
          this.conectarStripe = false;
          const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : this.$t("piggybank.iban.errorUnexpected");
          this.$emit("errorAddingIban", errorMessage);
          this.loading = false;

          console.error("Error al enviar el IBAN:", error);
        }
      }
    },
    validateIBANOnInput(iban) {
      // Remove spaces and convert to uppercase
      iban = iban.replace(/\s+/g, "").toUpperCase();

      // Check if the length is correct (country-specific)
      if (iban.length < 15) {
        this.errorIBAN = this.$t("piggybank.iban.errorMinLength");
        return false;
      } else if (iban.length > 31) {
        this.errorIBAN = this.$t("piggybank.iban.errorMaxLength");
        return false;
      }

      let value = iban;
      let rearrange =
        value.substring(4, value.length)
        + value.substring(0, 4);
      let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
      let alphaMap = {};
      let number = [];

      alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
      });

      rearrange.split("").forEach((value, index) => {
        number[index] = alphaMap[value] || value;
      });
      let res = this.modulo(number.join("").toString(), 97) === 1;
      if (!res) {
        this.errorIBAN = this.$t("piggybank.iban.errorInvalid");
      } else {
        this.errorIBAN = "";
      }
    },
    modulo(aNumStr, aDiv) {
      var tmp = "";
      var i, r;
      for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
      }
      return tmp / 1;
    },
    initiateProcess() {
      this.normalFlow = false;
      this.conectarStripe = true;
    }
  },
  watch: {
    AccountNumber: function(newVal) {
      this.validateForm();
    },
    AccountHolderName: function() {
      this.validateForm();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
